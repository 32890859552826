import React, { useState } from "react";
import { Paper, Box, TextField, Typography, Button } from "@mui/material/";
import { Avatar } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "../../components/Header";
import { Container } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import CustomSnackbar from "../../components/SnackbarComponent";

function LoginPageLicenta() {
  const API_URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const theme = createTheme();

  const [loginEmail, setLoginEmail] = useState("");
  const [loginCNP, setLoginCNP] = useState({
    cnp: "",
    showCNP: false,
  });

  const [starePlatformaMaster, setStarePlatformaMaster] = useState();

  useEffect(() => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${API_URL_ENDPOINT}/licenta/comisie/administrare/platforma`,
      validateStatus: () => {
        return true;
      },
    }).then((res) => {
      if (res.status === 200) {
        setStarePlatformaMaster(res.data.platformaMaster);
      }
    });
  }, []);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  };

  const handleClickShowCNP = () => {
    setLoginCNP({ ...loginCNP, showCNP: !loginCNP.showCNP });
  };

  const handleMouseDownCNP = (event) => {
    event.preventDefault();
  };

  const handleCNPChange = (prop) => (event) => {
    setLoginCNP({ ...loginCNP, [prop]: event.target.value.trim() });
  };

  let navigate = useNavigate();

  function login() {
    axios({
      method: "POST",
      data: {
        email: loginEmail.toLowerCase().trim(),
        cnp: loginCNP.cnp,
      },
      withCredentials: true,
      url: `${API_URL_ENDPOINT}/master/verificare/autentificare`,
      validateStatus: () => {
        return true;
      },
    }).then((res) => {
      if (res.status === 200 && res.data.statut === "student") {
        sessionStorage.setItem("token", res.data.token);
        navigate("/master/studentinfo");
      } else if (res.status === 404 && res.data.statut === "neidentificat") {
        setSnackbarState({
          open: true,
          message: "Eroare la conectare!",
          severity: "error",
        });
      } else if (res.status === 405) {
        setSnackbarState({
          open: true,
          message: res.data.error,
          severity: "error",
        });
      }
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <Header />
        <Paper
          elevation={10}
          sx={{
            mt: { xs: 10, sm: 10, md: 15, lg: 10 },
            mb: "auto",
            mx: "auto",
            width: { xs: `80%`, sm: `60%`, md: `70%`, lg: `60%` },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "25px",
          }}
        >
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <AccountCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Autentificare - MASTER
            </Typography>
            <Box
              component="form"
              noValidate
              sx={{
                // mt: 1
                display: "grid",
                padding: 2,
                mt: 1,
              }}
            >
              <TextField
                margin="normal"
                required
                // fullWidth
                id="email"
                label="Adresa de e-mail"
                inputProps={{ style: { fontSize: 17 } }}
                autoFocus
                onChange={(e) =>
                  setLoginEmail(e.target.value.toLowerCase().trim())
                }
              />
              <TextField
                margin="normal"
                required
                // fullWidth
                id="cnp"
                label="CNP"
                inputProps={{ style: { fontSize: 17 } }}
                type={loginCNP.showCNP ? "text" : "password"}
                onChange={handleCNPChange("cnp")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowCNP}
                        onMouseDown={handleMouseDownCNP}
                      >
                        {loginCNP.showCNP ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 8 }}
                onClick={() => {
                  login();
                }}
                disabled={!starePlatformaMaster}
              >
                Autentificare
              </Button>
            </Box>
            <Typography fontWeight="bold" sx={{ m: 2 }} align="center">
              NOTĂ: Se va folosi adresa de e-mail folosită la crearea contului
              de pe platforma de înscriere la programul de masterat!
            </Typography>
          </Box>
        </Paper>
      </Container>
      <CustomSnackbar {...snackbarState} onClose={handleCloseSnackbar} />
    </ThemeProvider>
  );
}

export default LoginPageLicenta;
