import React, { useState } from "react";
import { Paper, Box, TextField, Typography, Button } from "@mui/material/";
import { Avatar } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "../components/Header";
import { Container } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import CustomSnackbar from "../components/SnackbarComponent";

function LoginPageComisie() {
  const API_URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const theme = createTheme();

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState({
    password: "",
    showPassword: false,
  });

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  };

  const handleClickShowPassword = () => {
    setLoginPassword({
      ...loginPassword,
      showPassword: !loginPassword.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setLoginPassword({ ...loginPassword, [prop]: event.target.value.trim() });
  };

  let navigate = useNavigate();

  function login(optiuneLogin) {
    axios({
      method: "POST",
      data: {
        email: loginEmail.toLowerCase().trim(),
        password: loginPassword.password,
      },
      withCredentials: true,
      url: `${API_URL_ENDPOINT}/licenta/verificare/autentificare/comisie`,
      validateStatus: () => {
        return true;
      },
    }).then((res) => {
      if (res.status === 200) {
        sessionStorage.setItem("token", res.data.token);
        switch (optiuneLogin) {
          case "Licenta":
            navigate("/licenta/comisie");
            break;
          case "Master":
            navigate("/master/comisie");
            break;
          default:
            break;
        }
      } else if (res.status === 404) {
        setSnackbarState({
          open: true,
          message: "Nu faci parte din comisie!",
          severity: "error",
        });
      } else if (res.status === 403) {
        setSnackbarState({
          open: true,
          message: "Email sau parolă greșite!",
          severity: "error",
        });
      }
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <Header />
        <Paper
          elevation={10}
          sx={{
            mt: { xs: 10, sm: 10, md: 15, lg: 10 },
            mb: "auto",
            mx: "auto",
            width: { xs: `80%`, sm: `60%`, md: `70%`, lg: `60%` },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "25px",
          }}
        >
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <AccountCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h5" fontWeight="bold">
              Autentificare - COMISIE
            </Typography>
            <Box
              component="form"
              noValidate
              sx={{
                // mt: 1
                display: "grid",
                padding: 2,
                mt: 1,
              }}
            >
              <TextField
                margin="normal"
                required
                // fullWidth
                id="email"
                label="Adresa de email"
                inputProps={{ style: { fontSize: 17 } }}
                autoFocus
                onChange={(e) =>
                  setLoginEmail(e.target.value.toLowerCase().trim())
                }
              />
              <TextField
                margin="normal"
                required
                // fullWidth
                id="password"
                label="Parolă"
                inputProps={{ style: { fontSize: 17 } }}
                type={loginPassword.showPassword ? "text" : "password"}
                onChange={handlePasswordChange("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {loginPassword.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => {
                  login("Licenta");
                }}
              >
                Autentificare - LICENȚĂ
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 8 }}
                onClick={() => {
                  login("Master");
                }}
              >
                Autentificare - MASTER
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
      <CustomSnackbar {...snackbarState} onClose={handleCloseSnackbar} />
    </ThemeProvider>
  );
}

export default LoginPageComisie;
