import Warning from "@mui/icons-material/Warning";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";

export default function InfoDialog() {
  const [showDialog, setShowDialog] = useState(false);
  const fullScreen = useMediaQuery("(max-width:600px)");

  return (
    <>
      <Button
        variant="contained"
        startIcon={<Warning />}
        sx={{ bgcolor: "#fea400", color: "black" }}
        onClick={() => setShowDialog(true)}
      >
        Informații despre plata taxei de înscriere
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle>Plata taxei de înscriere</DialogTitle>
        <DialogContent sx={{ textAlign: "justify" }}>
          <Typography variant="body">
            Pentru a vă putea înscrie la modulul psihopedagogic atât la nivelul
            I, cât și la nivelul al II-lea este necesar să achitați taxa unică
            de <b>125 RON</b> prin intermediul platformei de plăți (disponibilă
            în cadrul paginii de încărcare a documentelor, dând click pe butonul{" "}
            <b>Plătește taxă înscriere</b> - tutorial mai jos){" "}
            <span style={{ color: "red" }}>
              <b>SAU</b>
            </span>{" "}
            prin ordin de plată (OP).
          </Typography>
          <br />
          <br />
          <Typography variant="body">
            Dacă alegeți plata prin OP, vă rugăm să virați suma de{" "}
            <b>125 RON</b> într-unul dintre conturile următoare:
            <ul
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <li>
                <b>RO16RNCB0072001607860102</b> – cont în <b>lei</b>, deschis la
                BCR, cod fiscal 4433775, ASE din București;
              </li>
              <li>
                <b>RO59BRDE445SV36571854450</b> – cont în <b>lei</b>, deschis la
                BRD agenția ASE, cod fiscal 4433775, ASE din București;
              </li>
              <li>
                <b>RO15RNCB0072001607860023</b> – cont în valută <b>euro</b>,
                deschis la BCR, SWIFT RNCROBU, cod fiscal 4433775, ASE din
                București.
              </li>
            </ul>
          </Typography>
          <Typography variant="body">
            Pe ordinul de plată, candidații sunt obligați să specifice
            următoarele informații: Beneficiar ‒ ASE din București, iar la
            detalierea plății: <b>tipul taxei</b> (taxă înscriere/taxă
            școlarizare DPPD), <b>nume</b>, <b>inițiala/inițialele tatălui</b>,{" "}
            <b>prenume</b> (pentru doamne va fi trecut numele conform
            certificatului de naștere) și{" "}
            <b>
              CNP, pentru candidații de cetățenie română, respectiv ID, pentru
              candidații străini
            </b>{" "}
            (codul generat de platforma <b>admitere.ase.ro</b>).
          </Typography>
          <br />
          <br />
          <Typography variant="body">
            <b>Foarte important</b>: Pe ordinul de plată vor fi trecute datele
            candidatului la admitere la DPPD cu privire la{" "}
            <i>nume, inițiala/inițialele tatălui, prenume</i> (pentru doamne va
            fi trecut numele conform certificatului de naștere) și{" "}
            <span style={{ color: "red" }}>
              <b>NU</b>
            </span>{" "}
            ale persoanei care face plata, dacă aceasta este diferită de
            candidat.
            <br />
            <br />
            <b>Exemplu</b>: taxa de înscriere DPPD, Ionescu M. Claudiu Răzvan,
            5020127151771.
          </Typography>
          <br />
          <br />
          După efectuarea transferului bancar se va verifica plata de către
          Departamentul Financiar. Prin urmare, este necesar <b>
            să încărcați
          </b>{" "}
          ordinul de plată pe pagina corespunzătoare aferentă documentelor din
          cadrul acestei platforme de înscriere. Verificați statusul plății în
          pagina de încărcare a documentelor! În caz că OP-ul a fost refuzat,
          veți primi un mail și veți putea încărca un nou OP.
          <br />
          <br />
          <u>
            <Typography
              variant="h5"
              sx={{
                color: "red",
                fontWeight: "600",
              }}
            >
              Tutorial plată prin platforma de plăți
            </Typography>
          </u>
          <ol>
            <li>
              Accesați{" "}
              <a
                href="https://plationline.ase.ro/cauta-pers.php"
                target="_blank"
              >
                pagina de plată
              </a>
              , completați CNP-ul dumneavostră, bifați căsuța de acceptare a
              termenilor, condițiilor de plată și a GDPR-ului și apăsați butonul{" "}
              <b>Plătește</b>;
            </li>
            <li>Completați datele dumneavoastră personale;</li>
            <li>
              Selectați <b>Categoria taxei</b> -{" "}
              <b>
                Taxe diverse pentru programe de formare psihopedagogica
                organizate in cadrul DPPD
              </b>
              ;
            </li>
            <li>
              Selectați <b>Tipul taxei</b> - <b>Taxă înscriere DPPD</b>;
            </li>
            <li>
              Apăsați butonul <b>Plătește</b> și urmați pașii din pagina
              procesatorului de plăți <i>euplătesc.ro</i>;
            </li>
            <li>
              După confirmarea plății reveniți în pagina în care ați încărcat
              documentele, iar statusul efectuării plății va fi actualizat.
            </li>
          </ol>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={() => setShowDialog(false)}
          >
            Am înțeles ✅
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
