import React, { useState } from "react";
import {
  Paper,
  Typography,
  Container,
  Grid,
  Box,
  Checkbox,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableFooter,
} from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import CheckIcon from "@mui/icons-material/Check";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Tooltip from "@mui/material/Tooltip";
import { useEffect } from "react";
import axios from "axios";
import FileDownload from "js-file-download";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, InputLabel, Input } from "@mui/material";
import CustomSnackbar from "../../components/SnackbarComponent";

const styles = {
  container: {
    minWidth: 1700,
    margin: "0 auto",
  },
};

function ComisiePageMaster() {
  const API_URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const NR_MAX_STUDENTI_ADMISI = 150;
  const theme = createTheme();
  let navigate = useNavigate();

  const token = sessionStorage.getItem("token");

  const [page, setPage] = React.useState(0);
  const [tipAfisare, setTipAfisare] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [studenti, setStudenti] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [tipDialog, setTipDialog] = useState(0);
  const [studentSelectat, setStudentSelectat] = useState();
  const [optiuneStudentRespins, setOptiuneStudentRespins] = useState();
  const [fisiereStudent, setFisiereStudent] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [respingeriStudent, setRespingeriStudent] = useState({});
  const [descarcaFisiere, setDescarcaFisiere] = useState(false);
  const [nrStudentiAdmisi, setNrStudentiAdmisi] = useState(0);
  const [loading, setLoading] = useState(false);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  };

  useEffect(() => {
    axios
      .get(`${API_URL_ENDPOINT}/nrAdmisi`, {
        params: { token: token, program: "master" },
      })
      .then((res) => {
        if (res.status === 200) {
          setNrStudentiAdmisi(res.data.nrAdmisi);
        } else if (res.status === 401) {
          navigate("/comisie/login");
        }
      });
  }, []);

  const handleClickOpenDialog = (student, tipDialog) => {
    setTipDialog(tipDialog);
    setStudentSelectat(student);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    axios
      .get(`${API_URL_ENDPOINT}/master/comisie/studenti`, {
        params: {
          calificativ: tipAfisare,
          token: token,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          setDescarcaFisiere(response.data.fisiereDescarcate);
          setStudenti(response.data);
        } else if (response.status == 401) {
          navigate("/comisie/login");
        } else {
          navigate("/comisie/login");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [tipAfisare, descarcaFisiere]);

  const handleAcordaPreluare = (emailStudentPreluat) => {
    axios({
      method: "POST",
      data: {
        token: token,
        emailStudent: emailStudentPreluat,
      },
      url: `${API_URL_ENDPOINT}/master/preluare/student`,
    }).then((res) => {
      if (res.status === 200) {
        setSnackbarState({
          open: true,
          message: "Student preluat!",
          severity: "success",
        });
        setTimeout(function () {
          window.location.reload();
        }, 500);
      } else if (res.status === 201) {
        setSnackbarState({
          open: true,
          message: "Student deja preluat!",
          severity: "warning",
        });
        setTimeout(function () {
          window.location.reload();
        }, 500);
      } else if (res.status === 401) {
        navigate("/comisie/login");
      }
    });
  };

  const handleAcordaCalificativStudent = (emailStudent, calificativAcordat) => {
    axios({
      method: "POST",
      data: {
        token: token,
        emailStudent: emailStudent,
        calificativ: calificativAcordat,
      },
      url: `${API_URL_ENDPOINT}/master/comisie/calificativ`,
    }).then((res) => {
      if (res.status === 200) {
        if (calificativAcordat === 2) {
          setSnackbarState({
            open: true,
            severity: "success",
            message: "Student admis",
          });
        } else if (calificativAcordat === 3) {
          setSnackbarState({
            open: true,
            severity: "warning",
            message: "Student respins",
          });
        }

        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else {
        navigate("/licenta/comisie/login");
      }
    });
  };

  const handleDownloadZip = async (
    emailStudent,
    numeStudent,
    prenumeStudent
  ) => {
    try {
      setSnackbarState({
        open: true,
        message: "Se descarcă fișierele...",
        severity: "info",
      });
      const data = {
        email: emailStudent,
        token: token,
      };
      const response = await axios.post(
        `${API_URL_ENDPOINT}/master/descarcare/zip`,
        data,
        { responseType: "blob" }
      );
      if (response.status === 200) {
        FileDownload(response.data, `${numeStudent}_${prenumeStudent}.zip`);
      } else if (response.status === 401) {
        navigate("/comisie/login");
      } else {
        setSnackbarState({
          open: true,
          message: `${response.data.error}`,
          severity: "error",
        });
      }
    } catch (error) {
      setSnackbarState({
        open: true,
        message: `Eroare la descărcarea fișierelor! \n ${error}`,
        severity: "error",
      });
    }
  };

  const handleClickDialogAction = () => {
    setLoading(true);
    axios({
      method: "POST",
      data: {
        token: token,
        email: studentSelectat.email,
        respingeriStudent: respingeriStudent,
      },
      url: `${API_URL_ENDPOINT}/master/optiune/respingere/student`,
    }).then((res) => {
      if (res.status === 200) {
        setSnackbarState({
          open: true,
          severity: "success",
          message: "Respingere înregistrată!",
        });
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      } else if (res.status === 401) {
        navigate("/comisie/login");
      } else {
        setSnackbarState({
          open: true,
          severity: "error",
          message: res.data.error,
        });
        setTimeout(function () {
          window.location.reload();
        }, 1000);
      }
    });
  };

  const handleVizualizareDocumenteStudent = (student) => {
    axios({
      method: "POST",
      data: {
        token: token,
        emailStudent: student.email,
      },
      url: `${API_URL_ENDPOINT}/master/comisie/fisiere/student`,
    }).then((res) => {
      if (res.status === 200) {
        setFisiereStudent(res.data);
        handleClickOpenDialog(student, 1);
      } else {
        navigate("/comisie/login");
      }
    });
  };

  const handleChangeOptiuneStudentRespins = (event) => {
    setRespingeriStudent({
      ...respingeriStudent,
      [event.target.value]: event.target.checked,
    });
  };

  const handleSchimbaTipAfisare = (tipAfisareCerut) => {
    setTipAfisare(tipAfisareCerut);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" sx={styles.container}>
        <Paper elevation={2} sx={{ mt: 2, mb: 5 }}>
          <Typography variant="h5" align="center" sx={{ pt: 5 }}>
            Studenți - MASTER -{" "}
            {tipAfisare === 1
              ? "ÎN AȘTEPTARE"
              : tipAfisare === 2
              ? "ADMIȘI"
              : "RESPINȘI"}
          </Typography>
          <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
            <Grid item>
              <Button
                variant="contained"
                color="warning"
                sx={{ mb: 2 }}
                onClick={() => handleSchimbaTipAfisare(1)}
              >
                În așteptare
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                sx={{ ml: 2, mb: 2 }}
                onClick={() => handleSchimbaTipAfisare(2)}
              >
                Admiși
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                sx={{ ml: 2, mb: 2, mr: 2 }}
                onClick={() => handleSchimbaTipAfisare(3)}
              >
                Respinși
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="info"
                sx={{ ml: 2, mb: 2, mr: 2 }}
                onClick={() => navigate("/master/comisiestatistici")}
              >
                Statistici
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Box>
            <FormControl sx={{ minWidth: 200, m: 2 }}>
              <InputLabel htmlFor="name-filter-input">
                Filtrează după nume
              </InputLabel>
              <Input
                id="name-filter-input"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                aria-describedby="name-filter-helper-text"
              />
            </FormControl>
            <FormControl sx={{ minWidth: 200, m: 2 }}>
              <InputLabel htmlFor="email-filter-input">
                Filtrează după e-mail
              </InputLabel>
              <Input
                id="email-filter-input"
                value={emailFilter}
                onChange={(e) => setEmailFilter(e.target.value)}
                aria-describedby="email-filter-helper-text"
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              marginLeft: "auto",
            }}
          >
            <Typography variant="h5" mt={3}>
              Număr studenți admiși: {nrStudentiAdmisi}/{NR_MAX_STUDENTI_ADMISI}
            </Typography>
          </Box>
        </Box>
        {studentSelectat != null ? (
          tipDialog === 2 ? (
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>
                Doriți să respingeți studentul {studentSelectat.nume}{" "}
                {studentSelectat.prenume}?
              </DialogTitle>
              <DialogContent>
                <Typography>Adresa e-mail: {studentSelectat.email}</Typography>
                <Typography>
                  Număr telefon: {studentSelectat.nrTelefon}
                </Typography>
                <FormControl>
                  <Box role="group" display="flex" flexDirection="column">
                    <FormControlLabel
                      value="incarcatCerereInscriereDPPD"
                      control={<Checkbox />}
                      label="Cerere înscriere DPPD neîncărcată"
                      onChange={handleChangeOptiuneStudentRespins}
                    />
                    <FormControlLabel
                      value="incarcatAdevLicenta"
                      control={<Checkbox />}
                      label="Adeverință licență neîncărcată"
                      onChange={handleChangeOptiuneStudentRespins}
                    />
                    <FormControlLabel
                      value="incarcatDiplomaLicenta"
                      control={<Checkbox />}
                      label="Diplomă licență neîncărcată"
                      onChange={handleChangeOptiuneStudentRespins}
                    />
                    <FormControlLabel
                      value="incarcatFoaieMatricola"
                      control={<Checkbox />}
                      label="Foaie matricolă neîncărcată"
                      onChange={handleChangeOptiuneStudentRespins}
                    />
                    <FormControlLabel
                      value="incarcatAdevNivI"
                      control={<Checkbox />}
                      label="Adeverință nivel I neîncărcată"
                      onChange={handleChangeOptiuneStudentRespins}
                    />
                    <FormControlLabel
                      value="incarcatDiplomaNivI"
                      control={<Checkbox />}
                      label="Diplomă nivel I neîncărcată"
                      onChange={handleChangeOptiuneStudentRespins}
                    />
                    <FormControlLabel
                      value="incarcatDeclaratieAutenticitateActe"
                      control={<Checkbox />}
                      label="Declarație autenticitate acte neîncărcată"
                      onChange={handleChangeOptiuneStudentRespins}
                    />
                  </Box>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    handleCloseDialog();
                  }}
                >
                  Ieșire
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => {
                    handleClickDialogAction(studentSelectat.email);
                  }}
                >
                  Trimite
                </Button>
              </DialogActions>
            </Dialog>
          ) : tipDialog === 1 ? (
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>
                Fișiere {studentSelectat.nume} {studentSelectat.prenume}:
              </DialogTitle>
              <DialogContent>
                <Box sx={{ flexDirection: "column", mt: 1 }}>
                  {fisiereStudent.map((fileName, index) => (
                    <Typography key={index}>
                      {index + 1}.{fileName}
                    </Typography>
                  ))}
                </Box>
              </DialogContent>
              <Button
                sx={{ m: 2 }}
                variant="contained"
                onClick={() => {
                  handleCloseDialog();
                }}
              >
                Ieșire
              </Button>
            </Dialog>
          ) : null
        ) : null}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead
              sx={{ bgcolor: "powderblue", borderBottom: "2px solid black" }}
            >
              <TableRow key="1">
                <TableCell align="center" width="5%">
                  Calificativ
                </TableCell>
                <TableCell align="center" width="10%">
                  E-mail
                </TableCell>
                <TableCell align="center" width="10%">
                  Nume
                </TableCell>
                <TableCell align="center" width="10%">
                  Prenume
                </TableCell>
                <TableCell align="center" width="10%">
                  Facultate
                </TableCell>
                <TableCell align="center" width="10%">
                  Program
                </TableCell>
                <TableCell align="center" width="5%">
                  Preluare student
                </TableCell>
                <TableCell align="center" width="15%">
                  Punctaj chestionar
                </TableCell>
                <TableCell align="center" width="15%">
                  Fișiere student
                </TableCell>
                <TableCell align="center" width="15%">
                  Probleme acte
                </TableCell>
                <TableCell align="center" width="20%">
                  Acordă calificativ
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studenti
                .filter(
                  (row) =>
                    row.nume.toLowerCase().includes(nameFilter.toLowerCase()) &&
                    row.email.toLowerCase().includes(emailFilter.toLowerCase())
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell width="5%">
                      {row.calificativ === 1 && row.preluat === "" ? (
                        <Tooltip title="În așteptare - NEPRELUAT">
                          <NewReleasesIcon color="warning" />
                        </Tooltip>
                      ) : row.calificativ === 1 && row.preluat != "" ? (
                        <Tooltip title="În așteptare - PRELUAT">
                          <CheckIcon color="info" />
                        </Tooltip>
                      ) : row.calificativ === 2 ? (
                        <Tooltip title="ADMIS">
                          <DoneAllIcon color="success" />
                        </Tooltip>
                      ) : (
                        <Tooltip title="RESPINS">
                          <ErrorOutlinedIcon color="error" />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {row.email}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {row.nume}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {row.prenume}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {row.facultate}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {row.program}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {row.calificativ === 1 && row.preluat === "" ? (
                        <Button
                          variant="contained"
                          onClick={() => handleAcordaPreluare(row.email)}
                        >
                          Preluare student
                        </Button>
                      ) : (
                        <Typography>Preluat: {row.preluat} </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center" width="5%">
                      {row.punctaj}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {row.preluat === "" && (
                        <Button variant="contained" disabled={true}>
                          Descărcare fișiere
                        </Button>
                      )}

                      {row.preluat !== "" && (
                        <>
                          <IconButton
                            color="info"
                            onClick={() =>
                              handleVizualizareDocumenteStudent(row)
                            }
                          >
                            <AttachFileIcon />
                          </IconButton>
                          <IconButton
                            color={
                              row.fisiereDescarcate === false
                                ? "info"
                                : "success"
                            }
                            onClick={() =>
                              handleDownloadZip(
                                row.email,
                                row.nume,
                                row.prenume
                              )
                            }
                          >
                            <DownloadIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                    <TableCell align="center" width="10%">
                      {row.preluat === "" && (
                        <Button variant="contained" disabled={true}>
                          Reîncărcare acte
                        </Button>
                      )}

                      {row.preluat !== "" && (
                        <Button
                          variant="contained"
                          onClick={() => handleClickOpenDialog(row, 2)}
                        >
                          Reîncărcare acte
                        </Button>
                      )}
                    </TableCell>
                    <TableCell align="center" width="20%">
                      {row.preluat === "" ? (
                        <>
                          <Button
                            variant="contained"
                            color="success"
                            sx={{ mb: 1 }}
                            disabled={true}
                          >
                            ADMIS
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            disabled={true}
                          >
                            RESPINS
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            color="success"
                            sx={{ mb: 1 }}
                            onClick={() =>
                              handleAcordaCalificativStudent(row.email, 2)
                            }
                          >
                            ADMIS
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              handleAcordaCalificativStudent(row.email, 3)
                            }
                          >
                            RESPINS
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  count={studenti.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <CustomSnackbar {...snackbarState} onClose={handleCloseSnackbar} />
      </Container>
    </ThemeProvider>
  );
}

export default ComisiePageMaster;
