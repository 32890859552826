import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

export default function Financiar() {
  const API_URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const [studenti, setStudenti] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    axios
      .get(`${API_URL_ENDPOINT}/financiar/studenti`, {
        params: { token: sessionStorage.getItem("token") },
      })
      .then((res) => {
        setStudenti(res.data);
      });
  }, []);

  const descarcaOP = (nivel, email) => {
    // Construct the URL for the file download
    const url = `/financiar/op?nivel=${encodeURIComponent(
      nivel
    )}&email=${encodeURIComponent(email)}`;

    // Open the file in a new tab
    window.open(url, "_blank");
  };

  const schimbaStatusPlata = (nivel, email, statusPlata) => {
    axios
      .put(
        `${API_URL_ENDPOINT}/financiar/studenti`,
        { nivel, email, statusPlata },
        { params: { token: sessionStorage.getItem("token") } }
      )
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      });
  };

  return (
    <Container component="main">
      <Typography variant="h4" align="center">
        Studenți ce au trimis OP
      </Typography>
      <TableContainer component={Paper} sx={{ minWidth: 1000 }}>
        <Table sx={{ minWidth: 800 }}>
          <TableHead
            sx={{ bgcolor: "powderblue", borderBottom: "2px solid black" }}
          >
            <TableRow>
              <TableCell align="center" width="10%">
                Nume
              </TableCell>
              <TableCell align="center" width="25%">
                Prenume
              </TableCell>
              <TableCell align="center" width="10%">
                CNP
              </TableCell>
              <TableCell align="center" width="10%">
                Telefon
              </TableCell>
              <TableCell align="center" width="5%">
                OP
              </TableCell>
              <TableCell align="center" width="50%">
                Acordă calificativ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studenti
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.email} align="center">
                  <TableCell width="10%" align="center">
                    {row.nume}
                  </TableCell>
                  <TableCell width="15%" align="center">
                    {row.prenume}
                  </TableCell>
                  <TableCell width="10%" align="center">
                    {row.cnp}
                  </TableCell>
                  <TableCell width="10%" align="center">
                    {row.nrTelefon}
                  </TableCell>
                  <TableCell width="5%" align="center">
                    <IconButton
                      onClick={() => descarcaOP(row.nivel, row.email)}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell width="50%" align="center">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        color="success"
                        variant="contained"
                        endIcon={<CheckIcon />}
                        onClick={() => {
                          schimbaStatusPlata(row.nivel, row.email, true);
                        }}
                      >
                        APROBĂ
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        endIcon={<CloseIcon />}
                        onClick={() => {
                          schimbaStatusPlata(row.nivel, row.email, false);
                        }}
                      >
                        RESPINGE
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={studenti.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
}
