import React, { useState } from "react";
import { Paper, Button, Tooltip, Typography } from "@mui/material/";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import CustomSnackbar from "../../components/SnackbarComponent";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BarChartIcon from "@mui/icons-material/BarChart";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import DownloadIcon from "@mui/icons-material/Download";
import DescriptionIcon from "@mui/icons-material/Description";
import WarningIcon from "@mui/icons-material/Warning";
import TaskIcon from "@mui/icons-material/Task";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import LinearProgress from "@mui/material/LinearProgress";

import { saveAs } from "file-saver";

import axios from "axios";

import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const styles = {
  container: {
    minWidth: 1700,
    margin: "0 auto",
    mt: 10,
  },
};

function ComisieStatisticiPageLicenta() {
  const API_URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const theme = createTheme();

  const token = sessionStorage.getItem("token");

  const [studenti, setStudenti] = useState({
    Asteptare: 0,
    Admisi: 0,
    Respinsi: 0,
    PunctajMaiMareCinci: 0,
    PunctajMaiMicCinci: 0,
  });
  const [valueTabs, setValueTabs] = React.useState(0);
  const [showProgressBar, setShowProgressBar] = React.useState(false);

  const [switchOnOff, setSwitchOnOff] = useState({
    platformaLicenta: false,
    chestionarLicenta: false,
    platformaMaster: false,
    chestionarMaster: false,
    generareChestionareLicenta: false,
    generareChestionareMaster: false,
  });

  useEffect(() => {
    axios
      .get(`${API_URL_ENDPOINT}/licenta/comisie/statistici`, {
        params: { token: token },
      })
      .then((response) => {
        setStudenti(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${API_URL_ENDPOINT}/licenta/comisie/administrare/platforma`,
      validateStatus: () => {
        return true;
      },
      params: { token: token },
    }).then((res) => {
      if (res.status === 200) {
        setSwitchOnOff(res.data);
      } else {
        handleOpenSnackbar("error", res.data.message);
        navigate("/comisie/login");
      }
    });
  }, [switchOnOff.generareChestionareLicenta]);

  const handleChangeStateSwitch = (event) => {
    const { name, checked } = event.target;
    setSwitchOnOff((prevSwitchState) => ({
      ...prevSwitchState,
      [name]: checked,
    }));

    axios({
      method: "POST",
      data: {
        optiune: name,
        valoareOptiune: checked,
      },
      withCredentials: true,
      url: `${API_URL_ENDPOINT}/licenta/comisie/administrare/platforma`,
      params: { token: token },
      validateStatus: () => {
        return true;
      },
    }).then((res) => {
      if (res.status === 200) {
        handleOpenSnackbar("success", res.data.message);
      } else {
        handleOpenSnackbar("error", res.data.message);
      }
    });
  };

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleOpenSnackbar = (severity, message) => {
    setSnackbarState({
      open: true,
      severity: severity,
      message: message,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  };

  const handleChangeTabsState = (event, newValue) => {
    setValueTabs(newValue);
  };

  let navigate = useNavigate();

  const generateBarChartData = () => {
    return {
      labels: ["Așteptare", "Admiși", "Respinși", "Punctaj > 5", "Punctaj < 5"],
      datasets: [
        {
          label: "Număr studenti",
          data: [
            studenti.Asteptare,
            studenti.Admisi,
            studenti.Respinsi,
            studenti.PunctajMaiMareCinci,
            studenti.PunctajMaiMicCinci,
          ],
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#008000",
            "#FF4500",
          ],
          borderWidth: 1,
        },
      ],
    };
  };

  const handleGenereazaChestionare = () => {
    setShowProgressBar(true);
    axios({
      method: "POST",
      withCredentials: true,
      url: `${API_URL_ENDPOINT}/licenta/comisie/generare/chestionare`,
      validateStatus: () => {
        return true;
      },
      params: { token: token },
    }).then((res) => {
      if (res.status === 200) {
        handleOpenSnackbar("success", "Chestionarele au fost generate!");
        setShowProgressBar(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        handleOpenSnackbar("error", res.data.error);
      }
    });
  };

  const handleDescarcaExcelStudentiAdmisi = async () => {
    try {
      const response = await axios.get(
        `${API_URL_ENDPOINT}/licenta/comisie/descarca/excel/studenti-admisi`,
        {
          params: {
            token: token,
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "StudentiAdmisiNivI.xlsx");
      } else if (response.status === 401) {
        navigate("/comisie/login");
      } else {
        navigate("/comisie/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDescarcaExcelStudentiRespinsi = async () => {
    try {
      const response = await axios.get(
        `${API_URL_ENDPOINT}/licenta/comisie/descarca/excel/studenti-respinsi`,
        {
          params: {
            token: token,
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "StudentiRespinsiNivI.xlsx");
      } else if (response.status === 401) {
        navigate("/comisie/login");
      } else {
        navigate("/comisie/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDescarcaChestionare = async () => {
    try {
      const response = await axios.get(
        `${API_URL_ENDPOINT}/licenta/comisie/descarca/chestionare`,
        {
          params: {
            token: token,
          },
          responseType: "blob",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "application/zip",
        });
        saveAs(blob, "ChestionareNivI.zip");
      } else {
        navigate("/comisie/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenereazaSituatie = async () => {
    axios
      .get(`${API_URL_ENDPOINT}/licenta/comisie/descarca/pdf/situatie`, {
        params: {
          token: token,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });
          saveAs(blob, "SituatieAdmisiRespinsiNivI.pdf");
        } else if (response.status === 500) {
          navigate("/comisie/login");
        } else {
          navigate("/comisie/login");
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" sx={styles.container}>
        <Typography fontWeight="bold" fontSize={20} sx={{ m: 10 }}>
          Statistici Licență
        </Typography>
        <Tabs
          value={valueTabs}
          onChange={handleChangeTabsState}
          aria-label="icon position tabs example"
        >
          <Tab
            icon={<BarChartIcon />}
            iconPosition="start"
            label="Statistici studenți"
          ></Tab>

          <Tab
            icon={<ToggleOnIcon />}
            iconPosition="start"
            label="Administrare platformă"
          ></Tab>
          <Tab
            icon={<DownloadIcon />}
            iconPosition="start"
            label="Descarcă rapoarte"
          ></Tab>
          <Tab
            icon={<DescriptionIcon />}
            iconPosition="start"
            label="Generează chestionare"
          ></Tab>
        </Tabs>
        <Paper elevation={3} sx={{ mt: 2 }}>
          {(() => {
            if (valueTabs === 0) {
              return (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <h1>Statistici studenți</h1>
                    <div
                      style={{
                        width: "100%",
                        justifyContent: "center",
                        minHeight: "30vh",
                        paddingLeft: "15vw",
                      }}
                    >
                      <Bar
                        data={generateBarChartData()}
                        options={{
                          indexAxis: "y",
                          responsive: true,
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                          scales: {
                            x: {
                              beginAtZero: true,
                            },
                            y: {
                              beginAtZero: true,
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            } else if (valueTabs === 1) {
              return (
                <>
                  <FormGroup sx={{ ml: 3 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="platformaLicenta"
                          checked={switchOnOff.platformaLicenta}
                          onChange={handleChangeStateSwitch}
                        />
                      }
                      label="Pornire / oprire platformă licență"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          name="chestionarLicenta"
                          checked={switchOnOff.chestionarLicenta}
                          onChange={handleChangeStateSwitch}
                        />
                      }
                      label="Pornire / oprire chestionar licență"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          name="platformaMaster"
                          checked={switchOnOff.platformaMaster}
                          onChange={handleChangeStateSwitch}
                        />
                      }
                      label="Pornire / oprire platformă master"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          name="chestionarMaster"
                          checked={switchOnOff.chestionarMaster}
                          onChange={handleChangeStateSwitch}
                        />
                      }
                      label="Pornire / oprire chestionar master"
                    />
                  </FormGroup>
                </>
              );
            } else if (valueTabs === 2) {
              return (
                <>
                  <Button
                    variant="contained"
                    onClick={() => handleDescarcaExcelStudentiAdmisi()}
                    sx={{ m: 3 }}
                  >
                    Descarcă Excel studenți admiși
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ m: 3 }}
                    onClick={handleDescarcaExcelStudentiRespinsi}
                  >
                    Descarcă Excel studenți respinși
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ m: 3 }}
                    onClick={handleGenereazaSituatie}
                  >
                    Generează situație admiși/respinși
                  </Button>
                </>
              );
            } else if (valueTabs === 3) {
              return (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">
                            <Button
                              variant="contained"
                              sx={{ m: 3 }}
                              onClick={() => handleGenereazaChestionare()}
                            >
                              Generează chestionare
                            </Button>
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="contained"
                              sx={{ m: 3 }}
                              onClick={() => handleDescarcaChestionare()}
                              disabled={!switchOnOff.generareChestionareLicenta}
                            >
                              Descarcă chestionare
                            </Button>
                          </TableCell>
                          <TableCell align="left">
                            {switchOnOff.generareChestionareLicenta ===
                            false ? (
                              <>
                                <Tooltip title="Chestionarele nu au fost încă generate!">
                                  <WarningIcon color="info" />
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                <Tooltip title="Chestionarele sunt create!">
                                  <TaskIcon color="success" />
                                </Tooltip>
                              </>
                            )}
                          </TableCell>
                          <TableCell>
                            {showProgressBar && <LinearProgress />}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              );
            }
          })()}
        </Paper>
        <Button
          variant="contained"
          onClick={() => navigate("/licenta/comisie")}
          sx={{ mx: "auto", my: 3 }}
        >
          Înapoi
        </Button>
        <CustomSnackbar {...snackbarState} onClose={handleCloseSnackbar} />
      </Container>
    </ThemeProvider>
  );
}

export default ComisieStatisticiPageLicenta;
