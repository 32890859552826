// React
import React from "react";

// Material UI
import { Grid, Typography, Button } from "@mui/material";

function Error404() {
  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      alignItems="center"
      xs
    >
      <Typography
        variant="h1"
        component="h1"
        color="primary"
        sx={{ fontSize: "15rem", fontWeight: "500" }}
      >
        404
      </Typography>
      <h2>Pagina nu a fost găsită.</h2>
      <Button variant="contained" color="primary" href="/">
        Înapoi la pagina principală
      </Button>
    </Grid>
  );
}

export default Error404;
