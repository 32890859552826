import React, { useState } from "react";
import { Paper, Box, Button, Divider, Grid, Tooltip } from "@mui/material/";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Container } from "@mui/system";
import Header from "../../components/Header";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import Chip from "@mui/material/Chip";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import CustomSnackbar from "../../components/SnackbarComponent";
import CloudDownload from "@mui/icons-material/CloudDownload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function UploadFileMaster() {
  const API_URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const theme = createTheme();
  const [dataFilePDF, setDataFilePDF] = useState();
  const [loadingBtn1, setLoadingBtn1] = useState(false);
  const [loadingBtn2, setLoadingBtn2] = useState(false);
  const [loadingBtn3, setLoadingBtn3] = useState(false);
  const [loadingBtn4, setLoadingBtn4] = useState(false);
  const [loadingBtn5, setLoadingBtn5] = useState(false);
  const [loadingBtn6, setLoadingBtn6] = useState(false);
  const [loadingBtn7, setLoadingBtn7] = useState(false);
  const [student, setStudent] = useState();
  const [detaliiPlata, setDetaliiPlata] = useState();
  const formateAcceptate = ["JPG", "JPEG", "PDF"];

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${API_URL_ENDPOINT}/master/stare/student`,
          {
            token: token,
          }
        );

        if (response.status === 200) {
          const studentData = response.data.student;
          setStudent(studentData);
        }
      } catch (error) {
        navigate("/master/login");
      }
    };

    fetchData();
  }, [dataFilePDF]);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleOpenSnackbar = (severity, message) => {
    setSnackbarState({
      open: true,
      severity: severity,
      message: message,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  };

  let navigate = useNavigate();

  const handleInputFile = (event, numeDocument) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const ext = file.name.split(".").pop();
      handleSendFilePDF(file, `${numeDocument}.${ext}`);
    }
  };

  const handleSendFilePDF = (file, numeDocument) => {
    if (!file) {
      handleOpenSnackbar("error", "Selectați un fișier pentru a încărca!");
      return;
    }

    const allowedTypes = ["application/pdf", "image/jpeg", "image/jpg"];
    if (!allowedTypes.includes(file.type)) {
      handleOpenSnackbar(
        "error",
        `Tipul de fișier nu este permis! Încărcați doar fișiere ${formateAcceptate.join(
          ", "
        )}.`
      );
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      handleOpenSnackbar(
        "error",
        "Dimensiunea fișierului este prea mare! Dimensiunea maximă permisă este de 5 MB."
      );
      return;
    }

    const formData = new FormData();
    formData.append("numeDocument", numeDocument);
    formData.append("token", token);
    formData.append("document", file);

    axios
      .post(`${API_URL_ENDPOINT}/master/upload`, formData, {
        params: { token: token, numeDocument: numeDocument.split(".")[0] },
      })
      .then((response) => {
        if (response.status === 200) {
          handleOpenSnackbar("success", `${numeDocument} încărcat cu succes!`);
          setDataFilePDF(file);
          setLoadingBtn1(false);
          setLoadingBtn2(false);
          setLoadingBtn3(false);
          setLoadingBtn4(false);
          setLoadingBtn5(false);
          setLoadingBtn6(false);
          setLoadingBtn7(false);
        } else {
          handleOpenSnackbar("error", response.data.error);
        }
      })
      .catch((error) => {
        handleOpenSnackbar("error", "Eroare la încărcarea fișierului!");
        console.error(error);
      });
  };

  const handleConfirmareDocumente = () => {
    axios
      .post(`${API_URL_ENDPOINT}/master/confirmare`, {
        token: token,
      })
      .then((response) => {
        if (response.status === 200) {
          handleOpenSnackbar("success", response.data.message);
          navigate("/master/studentinfo");
        } else {
          handleOpenSnackbar("error", response.data.error);
        }
      })
      .catch((error) => {
        handleOpenSnackbar("error", "Eroare la confirmarea documentelor!");
        console.error(error);
      });
  };

  const handleDownloadFile = (numeDocument) => {
    axios
      .post(
        `${API_URL_ENDPOINT}/master/download`,
        {
          token: token,
          numeDocument: numeDocument,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        handleOpenSnackbar("error", "Eroare la descărcarea fișierului!");
        console.error(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${API_URL_ENDPOINT}/verificare/plata`, {
        params: { token: token },
      })
      .then((res) => {
        if (res.status === 200) {
          setStudent((prevStudent) => ({
            ...prevStudent,
            statusPlata: res.data.plata,
          }));
          if (Object.keys(res.data).length > 1) {
            setDetaliiPlata({
              nrDoc: res.data.nrdoc,
              dataDoc: res.data.data,
            });
          }
        }
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <Header />
        <Paper elevation={8}>
          <Box
            sx={{
              marginTop: 8,
              ml: 2,
              mr: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              fontWeight="bold"
              variant="h5"
              sx={{
                mt: 2,
                alignContent: "center",
                textAlign: "center",
                fontSize: 20,
              }}
            >
              Încărcare fișiere înscriere
            </Typography>
            <Typography sx={{ p: 2 }}>
              NOTĂ: Fișierele trebuie să aibă maxim 5MB, iar extensia trebuie să
              fie oricare din{" "}
              <b>
                {"["}
                {formateAcceptate.join(", ")}
                {"]"}
              </b>
              !
            </Typography>
            <Accordion
              sx={{
                m: 2,
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                },
                width: "70%",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container direction="row" justifyContent="center">
                  <Typography fontWeight="bold">
                    Documente înscriere DPPD{" "}
                    {student &&
                      student.incarcatCerereInscriereDPPD &&
                      student.incarcatDeclaratieAutenticitateActe &&
                      "✅"}
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Paper sx={{ py: 2 }}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 2 }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Fișă înscriere
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container direction="row" justifyContent="center">
                        {student && (
                          <Button
                            component="label"
                            variant="contained"
                            role="incarcare-cerere-inscriere"
                            disabled={loadingBtn1}
                            startIcon={
                              loadingBtn1 ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <UploadFileIcon />
                              )
                            }
                          >
                            {loadingBtn1 ? "Așteptați..." : "Încarcă fișa"}
                            <VisuallyHiddenInput
                              type="file"
                              accept="application/pdf, image/jpeg, image/jpg"
                              onInput={(event) =>
                                handleInputFile(event, "[CerereInscriereDPPD]")
                              }
                            />
                          </Button>
                        )}
                        {student &&
                          student.incarcatCerereInscriereDPPD === true && (
                            <Button
                              variant="contained"
                              color="success"
                              startIcon={<CloudDownload />}
                              onClick={() =>
                                handleDownloadFile("[CerereInscriereDPPD]")
                              }
                            >
                              Descarcă fișă
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Declarație pe proprie răspundere
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container direction="row" justifyContent="center">
                        {student && (
                          <Button
                            variant="contained"
                            component="label"
                            role="incarcare-declaratie-autenticitate"
                            disabled={loadingBtn2}
                            startIcon={
                              loadingBtn2 ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <UploadFileIcon />
                              )
                            }
                          >
                            {loadingBtn2
                              ? "Așteptați..."
                              : "Încarcă declarația"}
                            <VisuallyHiddenInput
                              type="file"
                              accept="application/pdf, image/jpeg, image/jpg"
                              onInput={(event) =>
                                handleInputFile(
                                  event,
                                  "[DeclaratieAutenticitateActeDPPD]"
                                )
                              }
                            />
                          </Button>
                        )}{" "}
                        {student &&
                          student.incarcatDeclaratieAutenticitateActe ===
                            true && (
                            <Button
                              variant="contained"
                              color="success"
                              startIcon={<CloudDownload />}
                              onClick={() =>
                                handleDownloadFile(
                                  "[DeclaratieAutenticitateActeDPPD]"
                                )
                              }
                            >
                              Descarcă declarație
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                m: 2,
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                },
                width: "70%",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container direction="row" justifyContent="center">
                  <Typography fontWeight="bold">
                    Documente studii licență{" "}
                    {student &&
                      (student.incarcatAdevLicenta ||
                        (student.incarcatDiplomaLicenta &&
                          student.incarcatFoaieMatricola)) &&
                      "✅"}
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Paper sx={{ py: 2 }}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 2 }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Adeverință licență
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container direction="row" justifyContent="center">
                        {student && (
                          <Button
                            variant="contained"
                            component="label"
                            role="incarcare-adeverinta-licenta"
                            disabled={loadingBtn3}
                            startIcon={
                              loadingBtn3 ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <UploadFileIcon />
                              )
                            }
                          >
                            {loadingBtn3
                              ? "Așteptați..."
                              : "Încarcă adeverința"}
                            <VisuallyHiddenInput
                              type="file"
                              accept="application/pdf, image/jpeg, image/jpg"
                              onInput={(event) =>
                                handleInputFile(event, "[AdeverintaLicenta]")
                              }
                            />
                          </Button>
                        )}{" "}
                        {student && student.incarcatAdevLicenta && (
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<CloudDownload />}
                            onClick={() => {
                              handleDownloadFile("[AdeverintaLicenta]");
                            }}
                          >
                            Descarcă adeverință
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider>
                    <Chip variant="outlined" color="primary" label="SAU" />
                  </Divider>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Diplomă licență
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container direction="row" justifyContent="center">
                        {student && (
                          <Button
                            variant="contained"
                            component="label"
                            role="incarcare-diploma-licenta"
                            disabled={loadingBtn4}
                            startIcon={
                              loadingBtn4 ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <UploadFileIcon />
                              )
                            }
                          >
                            {loadingBtn4 ? "Așteptați..." : "Încarcă diploma"}
                            <VisuallyHiddenInput
                              type="file"
                              accept="application/pdf, image/jpeg, image/jpg"
                              onInput={(event) =>
                                handleInputFile(event, "[DiplomaLicenta]")
                              }
                            />
                          </Button>
                        )}
                        {student && student.incarcatDiplomaLicenta === true && (
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<CloudDownload />}
                            onClick={() => {
                              handleDownloadFile("[DiplomaLicenta]");
                            }}
                          >
                            Descarcă diplomă
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Foaie matricolă licență
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container direction="row" justifyContent="center">
                        {student && (
                          <Button
                            variant="contained"
                            component="label"
                            role="incarcare-foaie-matricola"
                            disabled={loadingBtn5}
                            startIcon={
                              loadingBtn5 ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <UploadFileIcon />
                              )
                            }
                          >
                            {loadingBtn5
                              ? "Așteptați..."
                              : "Încarcă foaia matricolă"}
                            <VisuallyHiddenInput
                              type="file"
                              accept="application/pdf, image/jpeg, image/jpg"
                              onInput={(event) =>
                                handleInputFile(
                                  event,
                                  "[FoaieMatricolaLicenta]"
                                )
                              }
                            />
                          </Button>
                        )}
                        {student && student.incarcatFoaieMatricola === true && (
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<CloudDownload />}
                            onClick={() => {
                              handleDownloadFile("[FoaieMatricolaLicenta]");
                            }}
                          >
                            Descarcă foaia matricolă
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{
                m: 2,
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                },
                width: "70%",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container direction="row" justifyContent="center">
                  <Typography fontWeight="bold">
                    Documente Nivel I DPPD{" "}
                    {student &&
                      (student.incarcatAdevNivI ||
                        student.incarcatDiplomaNivI) &&
                      "✅"}
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Paper sx={{ py: 2 }}>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ py: 2 }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Adeverință nivel I
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid direction="row" container justifyContent="center">
                        {student && (
                          <Button
                            variant="contained"
                            component="label"
                            role="incarcare-adeverinta-nivel-i"
                            disabled={loadingBtn6}
                            startIcon={
                              loadingBtn6 ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <UploadFileIcon />
                              )
                            }
                          >
                            {loadingBtn6
                              ? "Așteptați..."
                              : "Încarcă adeverința"}
                            <VisuallyHiddenInput
                              type="file"
                              accept="application/pdf, image/jpeg, image/jpg"
                              onInput={(event) =>
                                handleInputFile(event, "[AdeverintaNivelI]")
                              }
                            />
                          </Button>
                        )}
                        {student && student.incarcatAdevNivI && (
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<CloudDownload />}
                            onClick={() => {
                              handleDownloadFile("[AdeverintaNivelI]");
                            }}
                          >
                            Descarcă adeverință
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider>
                    <Chip variant="outlined" color="primary" label="SAU" />
                  </Divider>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Diplomă Nivel I
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Grid container justifyContent="center" direction="row">
                        {student && (
                          <Button
                            variant="contained"
                            component="label"
                            role="incarcare-diploma-nivel-i"
                            disabled={loadingBtn7}
                            startIcon={
                              loadingBtn7 ? (
                                <CircularProgress size={20} color="inherit" />
                              ) : (
                                <UploadFileIcon />
                              )
                            }
                          >
                            {loadingBtn7 ? "Așteptați..." : "Încarcă diploma"}
                            <VisuallyHiddenInput
                              type="file"
                              accept="application/pdf, image/jpeg, image/jpg"
                              onInput={(event) =>
                                handleInputFile(event, "[DiplomaNivelI]")
                              }
                            />
                          </Button>
                        )}
                        {student && student.incarcatDiplomaNivI === true && (
                          <Button
                            variant="contained"
                            color="success"
                            startIcon={<CloudDownload />}
                            onClick={() => {
                              handleDownloadFile("[DiplomaNivelI]");
                            }}
                          >
                            Descarcă diploma
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Paper
            sx={{
              mt: 2,
              mb: 2,
              py: 4,
              px: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" fontWeight="bold">
              Plata taxei de înscriere
            </Typography>
            {student?.statusPlata ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckBoxIcon color="success" />
                {detaliiPlata ? (
                  <Typography>{`Ai efectuat plata prin euplătesc.ro @ ${
                    detaliiPlata.nrDoc
                  } din ${new Date(
                    detaliiPlata.dataDoc
                  ).toLocaleDateString()}`}</Typography>
                ) : (
                  <Typography>Ai efectuat plata prin OP.</Typography>
                )}
              </Box>
            ) : !student?.incarcatOrdinPlataTaxaInscriereDPPD ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    startIcon={<ArrowForwardIcon />}
                    variant="contained"
                    onClick={() =>
                      (window.location.href =
                        "https://plationline.ase.ro/cauta-pers.php")
                    }
                  >
                    Plătește taxa de înscriere
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<AccountBalanceIcon />}
                  >
                    Încarcă dovada plății
                    <VisuallyHiddenInput
                      type="file"
                      accept="application/pdf, image/jpeg, image/jpg"
                      onInput={(event) => {
                        handleInputFile(event, "[OrdinPlataTaxaInscriereDPPD]");
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Verifică-ți mail-ul pentru confirmare!">
                  <CheckBoxIcon color="success" />
                </Tooltip>
                <Typography>OP încărcat. Se așteaptă validarea...</Typography>
              </Box>
            )}
          </Paper>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {student &&
              student.incarcatCerereInscriereDPPD &&
              student.incarcatDeclaratieAutenticitateActe &&
              (student.incarcatAdevLicenta ||
                (student.incarcatDiplomaLicenta &&
                  student.incarcatFoaieMatricola)) &&
              (student.incarcatAdevNivI || student.incarcatDiplomaNivI) &&
              student.statusPlata && (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<DoneAllIcon />}
                  onClick={handleConfirmareDocumente}
                >
                  Confirmă corectitudinea fișierelor încărcate
                </Button>
              )}
            <Button
              sx={{ m: 2 }}
              variant="contained"
              color="info"
              startIcon={<KeyboardReturnIcon />}
              onClick={() => {
                navigate("/master/studentinfo");
              }}
            >
              Înapoi
            </Button>
          </Grid>
        </Paper>
        <CustomSnackbar {...snackbarState} onClose={handleCloseSnackbar} />
      </Container>
    </ThemeProvider>
  );
}

export default UploadFileMaster;
