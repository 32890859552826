import React from "react";
import logo_dppd from "../photos/logo_dppd.png";
import logo_ase from "../photos/logo_ase.png";
import { Grid, Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import { Hidden } from "@mui/material";
function Header() {
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxWidth="lg"
      >
        <Hidden mdDown>
          <Grid item alignItems="center">
            <Box
              component="img"
              sx={{ height: 100, width: 100 }}
              src={logo_dppd}
              justifyContent="center"
            ></Box>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid item>
            <Box m={5}>
              <Typography align="center">
                Academia de Studii Economice din București
              </Typography>
              <Typography align="center">
                Departamentul pentru Pregătirea Personalului Didactic
              </Typography>
            </Box>
          </Grid>
        </Hidden>
        <Grid item>
          <Box
            component="img"
            sx={{ height: 100, width: 100 }}
            src={logo_ase}
          ></Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Header;
