import React, { useState } from "react";
import { Paper, Box, Button, Grid } from "@mui/material/";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { Container } from "@mui/system";
import Header from "../../components/Header";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CircularProgress from "@mui/material/CircularProgress";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Tooltip from "@mui/material/Tooltip";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DoneAllIcon from "@mui/icons-material/DoneAll";

import CustomSnackbar from "../../components/SnackbarComponent";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function UploadFileLicenta() {
  const API_URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const theme = createTheme();
  const [dataFilePDF, setDataFilePDF] = useState();
  const [loadingBtn1, setLoadingBtn1] = useState();
  const [loadingBtn2, setLoadingBtn2] = useState();
  const [student, setStudent] = useState();
  const formateAcceptate = ["JPG", "JPEG", "PDF"];
  const [detaliiPlata, setDetaliiPlata] = useState();

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${API_URL_ENDPOINT}/licenta/stare/student`,
          {
            token: token,
          }
        );

        if (response.status === 200) {
          const studentData = response.data.student;
          setStudent(studentData);
        }
      } catch (error) {
        navigate("/licenta/login");
      }
    };

    fetchData();
  }, [dataFilePDF]);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleOpenSnackbar = (severity, message) => {
    setSnackbarState({
      open: true,
      severity: severity,
      message: message,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  };

  let navigate = useNavigate();

  const handleInputFile = (event, numeDocument) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const ext = file.name.split(".").pop();
      handleSendFilePDF(file, `${numeDocument}.${ext}`);
    }
  };

  const handleSendFilePDF = (file, numeDocument) => {
    if (!file) {
      handleOpenSnackbar("error", "Selectați un fișier pentru a încărca!");
      return;
    }

    const allowedTypes = ["application/pdf", "image/jpeg", "image/jpg"];
    if (!allowedTypes.includes(file.type)) {
      handleOpenSnackbar(
        "error",
        `Tipul de fișier nu este permis! Încărcați doar fișiere ${formateAcceptate.join(
          ", "
        )}.`
      );
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      handleOpenSnackbar(
        "error",
        "Dimensiunea fișierului este prea mare! Dimensiunea maximă permisă este de 5 MB."
      );
      return;
    }

    const formData = new FormData();
    formData.append("numeDocument", numeDocument);
    formData.append("token", token);
    formData.append("document", file);

    axios
      .post(`${API_URL_ENDPOINT}/licenta/upload`, formData, {
        params: { token: token, numeDocument: numeDocument.split(".")[0] },
      })
      .then((response) => {
        if (response.status === 200) {
          handleOpenSnackbar("success", `${numeDocument} încărcat cu succes!`);
          setDataFilePDF(file);
          setLoadingBtn1(false);
          setLoadingBtn2(false);
        } else {
          handleOpenSnackbar("error", response.data.error);
        }
      })
      .catch((error) => {
        handleOpenSnackbar("error", "Eroare la încărcarea fișierului!");
        console.error(error);
      });
  };

  const handleConfirmareDocumente = () => {
    axios
      .post(`${API_URL_ENDPOINT}/licenta/confirmare`, {
        token: token,
      })
      .then((response) => {
        if (response.status === 200) {
          handleOpenSnackbar("success", response.data.message);
          navigate("/licenta/studentinfo");
        } else {
          handleOpenSnackbar("error", response.data.error);
        }
      })
      .catch((error) => {
        handleOpenSnackbar("error", "Eroare la confirmarea documentelor!");
        console.error(error);
      });
  };

  const handleDownloadFile = (numeDocument) => {
    axios
      .post(
        `${API_URL_ENDPOINT}/licenta/download`,
        {
          token: token,
          numeDocument: numeDocument,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        handleOpenSnackbar("error", "Eroare la descărcarea fișierului!");
        console.error(error);
      });
  };

  useEffect(() => {
    axios
      .get(`${API_URL_ENDPOINT}/verificare/plata`, {
        params: { token: token },
      })
      .then((res) => {
        if (res.status === 200) {
          setStudent((prevStudent) => ({
            ...prevStudent,
            statusPlata: res.data.plata,
          }));
          if (Object.keys(res.data).length > 1) {
            setDetaliiPlata({
              nrDoc: res.data.nrdoc,
              dataDoc: res.data.data,
            });
          }
        }
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <Header />
        <Paper elevation={8}>
          <Box
            sx={{
              marginTop: 8,
              ml: 2,
              mr: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                mt: 2,
                alignContent: "center",
                textAlign: "center",
                fontSize: 20,
              }}
              fontWeight="bold"
            >
              Încărcare fișiere înscriere
            </Typography>
            <Typography>
              NOTĂ: Fișierele trebuie să aibă maxim 5MB, iar extensia trebuie să
              fie oricare din{" "}
              <b>
                {"["}
                {formateAcceptate.join(", ")}
                {"]"}
              </b>
              !
            </Typography>

            <Paper sx={{ py: 2 }}>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ py: 2 }}
              >
                <Grid item xs={12} sm={3}>
                  <Typography variant="h5" fontWeight="bold">
                    Fișă înscriere
                  </Typography>
                  {student && student.incarcatCerereInscriereDPPD === true && (
                    <Tooltip title="Cerere înscriere încărcată">
                      <CheckBoxIcon color="success" />
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {student && (
                    <Button
                      component="label"
                      variant="contained"
                      role="incarcare-cerere-inscriere"
                      disabled={loadingBtn1}
                      startIcon={
                        loadingBtn1 ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <UploadFileIcon />
                        )
                      }
                    >
                      {loadingBtn1 ? "Așteptați..." : "Încarcă fișa"}
                      <VisuallyHiddenInput
                        type="file"
                        accept="application/pdf, image/jpeg, image/jpg"
                        onInput={(event) =>
                          handleInputFile(event, "[CerereInscriereDPPD]")
                        }
                      />
                    </Button>
                  )}
                  {student && student.incarcatCerereInscriereDPPD === true && (
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        component="label"
                        startIcon={<CloudDownloadIcon />}
                        onClick={() =>
                          handleDownloadFile("[CerereInscriereDPPD]")
                        }
                      >
                        Descarcă fișa
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={3}>
                  <Typography variant="h5" fontWeight="bold">
                    Declarație pe proprie răspundere
                  </Typography>
                  {student &&
                    student.incarcatDeclaratieAutenticitateActe === true && (
                      <Tooltip title="Declarație încărcată">
                        <CheckBoxIcon color="success" />
                      </Tooltip>
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {student && (
                    <Button
                      variant="contained"
                      role="incarcare-declaratie-autenticitate"
                      component="label"
                      disabled={
                        loadingBtn2 || !student.incarcatCerereInscriereDPPD
                      }
                      startIcon={
                        loadingBtn2 ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <UploadFileIcon />
                        )
                      }
                    >
                      {loadingBtn2 ? "Așteptați..." : "Încarcă declarația"}
                      <VisuallyHiddenInput
                        type="file"
                        accept="application/pdf, image/jpeg, image/jpg"
                        onInput={(event) => {
                          handleInputFile(
                            event,
                            "[DeclaratieAutenticitateActeDPPD]"
                          );
                        }}
                      />
                    </Button>
                  )}
                  {student &&
                    student.incarcatDeclaratieAutenticitateActe === true && (
                      <Grid item xs={12} sm={4}>
                        <Button
                          variant="contained"
                          component="label"
                          startIcon={<CloudDownloadIcon />}
                          onClick={() =>
                            handleDownloadFile(
                              "[DeclaratieAutenticitateActeDPPD]"
                            )
                          }
                        >
                          Descarcă declarația
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Paper>
            <Paper
              sx={{
                mt: 2,
                mb: 2,
                py: 4,
                px: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" fontWeight="bold">
                Plata taxei de înscriere
              </Typography>
              {student?.statusPlata ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CheckBoxIcon color="success" />
                  {detaliiPlata ? (
                    <Typography>{`Ai efectuat plata prin euplătesc.ro @ ${
                      detaliiPlata.nrDoc
                    } din ${new Date(
                      detaliiPlata.dataDoc
                    ).toLocaleDateString()}`}</Typography>
                  ) : (
                    <Typography>Ai efectuat plata prin OP.</Typography>
                  )}
                </Box>
              ) : !student?.incarcatOrdinPlataTaxaInscriereDPPD ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      startIcon={<ArrowForwardIcon />}
                      variant="contained"
                      onClick={() =>
                        (window.location.href =
                          "https://plationline.ase.ro/cauta-pers.php")
                      }
                    >
                      Plătește taxa de înscriere
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<AccountBalanceIcon />}
                    >
                      Încarcă dovada plății
                      <VisuallyHiddenInput
                        type="file"
                        accept="application/pdf, image/jpeg, image/jpg"
                        onInput={(event) => {
                          handleInputFile(
                            event,
                            "[OrdinPlataTaxaInscriereDPPD]"
                          );
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Verifică-ți mail-ul pentru confirmare!">
                    <CheckBoxIcon color="success" />
                  </Tooltip>
                  <Typography>OP încărcat. Se așteaptă validarea...</Typography>
                </Box>
              )}
            </Paper>
          </Box>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {student &&
              student.incarcatCerereInscriereDPPD === true &&
              student.incarcatDeclaratieAutenticitateActe === true &&
              student.statusPlata === true && (
                <Button
                  variant="contained"
                  startIcon={<DoneAllIcon />}
                  color="success"
                  sx={{ mt: 2, mb: 2 }}
                  onClick={handleConfirmareDocumente}
                >
                  Confirmă corectitudinea fișierelor încărcate
                </Button>
              )}
          </Grid>
        </Paper>
        <CustomSnackbar {...snackbarState} onClose={handleCloseSnackbar} />
      </Container>
    </ThemeProvider>
  );
}

export default UploadFileLicenta;
