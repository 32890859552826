import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import StartPage from "./pages/StartPage";
import LoginPageLicenta from "./pages/Licenta/LoginPageLicenta";
import LoginPageComisie from "./pages/LoginPageComisie";
import ComisiePageLicenta from "./pages/Licenta/ComisiePageLicenta";
import StudentInfoPageLicenta from "./pages/Licenta/StudentInfoPageLicenta";
import ChestionarPageLicenta from "./pages/Licenta/ChestionarPageLicenta";
import UploadFileLicentaPage from "./pages/Licenta/UploadFilePageLicenta";
import ComisieStatisticiPageLicenta from "./pages/Licenta/ComisieStatisticiPageLicenta";
import LoginPageMaster from "./pages/Master/LoginPageMaster";
import StudentInfoPageMaster from "./pages/Master/StudentInfoPageMaster";
import UploadFilePageMaster from "./pages/Master/UploadFilePageMaster";
import ChestionarPageMaster from "./pages/Master/ChestionarPageMaster";
import ComisiePageMaster from "./pages/Master/ComisiePageMaster";
import ComisieStatisticiPageMaster from "./pages/Master/ComisieStatisticiPageMaster";
import ErrorPage from "./pages/ErrorPage";
import LoginFinanciar from "./pages/LoginFinanciar";
import Financiar from "./pages/Financiar";
import InfoDialog from "./components/InfoDialog";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<StartPage />} />
      <Route exact path="/licenta/login" element={<LoginPageLicenta />} />
      <Route exact path="/comisie/login" element={<LoginPageComisie />} />
      <Route
        exact
        path="/licenta/chestionar"
        element={<ChestionarPageLicenta />}
      />
      <Route
        exact
        path="/licenta/upload/file"
        element={<UploadFileLicentaPage />}
      />
      <Route
        exact
        path="/licenta/studentinfo"
        element={<StudentInfoPageLicenta />}
      />
      <Route exact path="/licenta/comisie" element={<ComisiePageLicenta />} />
      <Route
        exact
        path="/licenta/comisiestatistici"
        element={<ComisieStatisticiPageLicenta />}
      />
      <Route exact path="/master/login" element={<LoginPageMaster />} />
      <Route exact path="/master/upload" element={<UploadFilePageMaster />} />
      <Route
        exact
        path="/master/chestionar"
        element={<ChestionarPageMaster />}
      />
      <Route
        exact
        path="/master/studentinfo"
        element={<StudentInfoPageMaster />}
      />
      <Route exact path="/master/comisie" element={<ComisiePageMaster />} />
      <Route
        exact
        path="/master/comisiestatistici"
        element={<ComisieStatisticiPageMaster />}
      />
      <Route exact path="/financiar/login" element={<LoginFinanciar />} />
      <Route exact path="/financiar" element={<Financiar />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  </Router>
);
