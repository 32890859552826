import React from "react";
import { Paper, Box, Typography, Button, Link, Stack } from "@mui/material/";
import { Avatar } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "../components/Header";
import { Container } from "@mui/system";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

import { useNavigate } from "react-router-dom";

function StartPage() {
  const theme = createTheme();

  let navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <Header />
        <Paper
          elevation={8}
          sx={{
            mt: { xs: 6, sm: 10, md: 12, lg: 4 },
            mb: "auto",
            mx: "auto",
            width: { xs: `100%`, sm: `80%`, md: `70%`, lg: `60%` },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "25px",
          }}
        >
          <Box
            sx={{
              //marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <SchoolIcon />
            </Avatar>
            <Typography component="h1" variant="h6" align="center">
              Bine ați venit la admiterea DPPD - {new Date().getFullYear()}!
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 1,
                  mb: 2,
                }}
              >
                <Button
                  variant="contained"
                  sx={{ mr: 2 }}
                  onClick={() => navigate("/licenta/login")}
                >
                  <ArrowCircleLeftIcon sx={{ mr: 2 }} />
                  Licență
                </Button>

                <Button
                  variant="contained"
                  sx={{ ml: 2 }}
                  onClick={() => navigate("/master/login")}
                >
                  Master
                  <ArrowCircleRightIcon sx={{ ml: 2 }} />
                </Button>
              </Box>
              <Box
                sx={{
                  mt: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={20} fontWeight="bold" align="center">
                  <Link
                    href="https://dppd.ase.ro/anunturi"
                    target="_blank"
                    rel="noopener"
                  >
                    Calendar admitere
                  </Link>
                </Typography>
                <Typography
                  sx={{ mt: 3, mb: 1 }}
                  align="center"
                  variant="body2"
                >
                  Suport tehnic:
                </Typography>
                <Button
                  variant="contained"
                  endIcon={<EmailIcon />}
                  size="small"
                  href="mailto:dppd.suport@ase.ro"
                >
                  Suport D.P.P.D.
                </Button>
                <Typography
                  sx={{ mt: 3, mb: 1 }}
                  a
                  align="center"
                  variant="body2"
                >
                  Informații suplimentare:
                </Typography>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mb: 2, ml: 2, mr: 2 }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    href="mailto:secretariat@dppd.ase.ro"
                    endIcon={<EmailIcon />}
                  >
                    Secretariat D.P.P.D.
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    href="tel:+40213191900;448"
                    startIcon={<PhoneIcon />}
                  >
                    021/319.19.00 int. 448
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

export default StartPage;
