import {
  Box,
  Button,
  createTheme,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomSnackbar from "../components/SnackbarComponent";
import axios from "axios";

export default function LoginFinanciar() {
  const API_URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const theme = createTheme();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState({
    password: "",
    showPassword: false,
  });
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  };

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    setPassword({ ...password, password: event.target.value.trim() });
  };

  let navigate = useNavigate();

  const login = () => {
    axios
      .post(`${API_URL_ENDPOINT}/financiar/login`, {
        user: email,
        parola: password.password,
      })
      .then((res) => {
        if (res.status === 200) {
          sessionStorage.setItem("token", res.data.token);
          navigate("/financiar");
        }
      })
      .catch((err) => {
        setSnackbarState({
          open: true,
          severity: "error",
          message: "Datele introduse sunt incorecte!",
        });
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <Header />
        <Paper
          elevation={10}
          sx={{
            mt: { xs: 10, sm: 10, md: 15, lg: 10 },
            mb: "auto",
            mx: "auto",
            width: { xs: `80%`, sm: `60%`, md: `70%`, lg: `60%` },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "25px",
          }}
        >
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
              Autentificare Financiar
            </Typography>
            <Box
              component="form"
              noValidate
              sx={{ display: "grid", padding: 2, mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                id="email"
                label="Adresa de e-mail"
                inputProps={{ style: { fontSize: 17 } }}
                autoFocus
                onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
              />
              <TextField
                margin="normal"
                required
                id="password"
                label="Parolă"
                inputProps={{ style: { fontSize: 17 } }}
                type={password.showPassword ? "text" : "password"}
                onChange={handlePasswordChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {password.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 8 }}
                onClick={() => login()}
              >
                Autentificare
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
      <CustomSnackbar {...snackbarState} onClose={handleCloseSnackbar} />
    </ThemeProvider>
  );
}
