import React, { useState } from "react";

import axios from "axios";
import { useEffect } from "react";
import Header from "../../components/Header";
import CustomSnackbar from "../../components/SnackbarComponent";
import {
  Grid,
  Paper,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Box,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/system";

function ChestionarPageLicenta() {
  const theme = createTheme();
  const API_URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    axios({
      method: "POST",
      data: {
        token: token,
      },
      withCredentials: false,
      url: `${API_URL_ENDPOINT}/licenta/verificare/token`,
      validateStatus: () => {
        return true;
      },
    }).then((res) => {
      if (res.status !== 200) {
        navigate("/licenta/login");
      }
    });
  }, []);

  const [intrebari, setIntrebari] = useState([]);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${API_URL_ENDPOINT}/licenta/stare/student`,
          {
            token: token,
          }
        );

        if (response.status === 200) {
          const studentData = response.data.student;
          if (studentData) {
            if (studentData.completatChestionar === true) {
              navigate("/licenta/studentinfo");
            }
          }
        }
      } catch (error) {
        navigate("/licenta/login");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    axios
      .get(`${API_URL_ENDPOINT}/licenta/intrebari`, {
        params: { token: token },
      })
      .then((response) => {
        setIntrebari(response.data);
      })
      .catch((error) => {
        console.error("Eroare la obținerea datelor:", error);
      });
  }, []);

  const handleRaspunsuri = (idIntrebare, varianta) => {
    setRaspunsuri({
      ...raspunsuri,
      [idIntrebare]: varianta,
    });
  };

  const [raspunsuri, setRaspunsuri] = useState({});

  let navigate = useNavigate();

  const trimiteRaspunsuri = () => {
    axios({
      method: "POST",
      data: {
        raspunsuri: Object.keys(raspunsuri).map((id) => ({
          intrebare: id,
          raspuns: raspunsuri[id],
        })),
        token: token,
        data: new Date(),
      },
      withCredentials: false,
      url: `${API_URL_ENDPOINT}/licenta/salveaza/raspunsuri`,
      validateStatus: () => {
        return true;
      },
    }).then((res) => {
      if (res.status === 200) {
        localStorage.removeItem("_timestamp");
        setSnackbarState({
          open: true,
          message: "Răspunsuri trimise cu succes! \n Redirecționare...",
          severity: "success",
        });
        setTimeout(() => {
          navigate("/licenta/studentinfo");
        }, 1000);
      } else {
        setSnackbarState({
          open: true,
          message: res.data.error,
          severity: "error",
        });
      }
    });
  };

  window.addEventListener("beforeunload", () => {
    localStorage.setItem("_timestamp", remainingTime);
  });

  const storedRemainingTime = localStorage.getItem("_timestamp");
  const initialRemainingTime = storedRemainingTime
    ? parseInt(storedRemainingTime)
    : 3600;

  const [remainingTime, setRemainingTime] = useState(initialRemainingTime);

  useEffect(() => {
    const timeout = setTimeout(() => {
      trimiteRaspunsuri();
    }, remainingTime * 1000);

    const interval = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [remainingTime]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <Header />
        <Paper elevation={8}>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{
                mt: 5,
                mb: 5,
                fontFamily: "monospace",
                fontWeight: "bold",
                fontSize: 30,
              }}
            >
              Test grilă DPPD
            </Typography>
            <Typography sx={{ mt: 2, mb: 2 }} fontWeight="bold" fontSize={20}>
              Timp rămas:{" "}
              {Math.floor(remainingTime / 60)
                .toString()
                .padStart(2, "0")}
              :{(remainingTime % 60).toString().padStart(2, "0")}
            </Typography>
            <FormControl name="raspunsuri">
              {intrebari.map((intrebare) => (
                <Paper
                  elevation={2}
                  sx={{ ml: 2, mb: 2, mr: 2 }}
                  key={intrebare.id}
                >
                  <Typography
                    sx={{ ml: 2, mt: 2, fontWeight: "bold", color: "black" }}
                  >
                    {intrebare.id}. {intrebare.questionText}
                  </Typography>
                  <RadioGroup
                    aria-label="slot"
                    key={intrebare.id}
                    sx={{ ml: 2 }}
                  >
                    {intrebare.answerOptions.map((raspuns) => (
                      <FormControlLabel
                        value={raspuns.varianta}
                        control={<Radio color="primary"></Radio>}
                        label={raspuns.varianta + ") " + raspuns.answerText}
                        onChange={() =>
                          handleRaspunsuri(intrebare.id, raspuns.varianta)
                        }
                      />
                    ))}
                  </RadioGroup>
                </Paper>
              ))}
            </FormControl>
            <Grid>
              <Button
                align="center"
                variant="contained"
                color="primary"
                sx={{ mb: 2 }}
                onClick={() => trimiteRaspunsuri()}
              >
                Trimite răspunsurile
              </Button>
            </Grid>
          </Box>
        </Paper>
        <CustomSnackbar {...snackbarState} onClose={handleCloseSnackbar} />
      </Container>
    </ThemeProvider>
  );
}

export default ChestionarPageLicenta;
