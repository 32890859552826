import React, { useState } from "react";
import { Paper, Box, Typography, Button } from "@mui/material/";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { Container } from "@mui/system";
import { useNavigate } from "react-router";
import Header from "../../components/Header";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import axios from "axios";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import Checkbox from "@mui/material/Checkbox";
import CustomSnackbar from "../../components/SnackbarComponent";
import Grid from "@mui/material/Grid";
import InfoDialog from "../../components/InfoDialog";

function StudentInfoLicenta() {
  const API_URL_ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const dataSustinere = "16.09.2024";
  const dataAfisareRezultate = "18.09.2024";
  const theme = createTheme();

  const token = sessionStorage.getItem("token");

  const [student, setStudent] = useState();
  const [pasStudent, setPasStudent] = useState(0);
  const [stareChestionarPlatforma, setStareChestionarPlatforma] =
    useState(false);
  const [checkedConsimtamant, setCheckedConsimtamant] = React.useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "success",
    message: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  };

  const steps = [
    "Incărcare fișiere necesare",
    "Completare chestionar",
    "Rezultate",
  ];

  const textPasiStudent = {
    pas1: "Pentru a participa la concurs, va fi nevoie să achitați taxa de 125 de RON și să incărcați fișa de înscriere la DPPD și declarația de autenticitate a actelor. Le găsiți la cele 2 link-uri de mai jos. Mai multe detalii despre cum puteți achita taxa regăsiți apăsând butonul de mai jos.",
    pas2: "Testul grilă conține 10 intrebări cu trei variante de răspuns, dintre care trebuie să selectați doar una dintre ele. Timpul de lucru este de o oră.",
  };

  const currentDate = new Date();
  const referenceDate = new Date("2024-09-18T00:00:00+03:00"); // data afisare rezultate

  const examMaxDate = new Date("2024-09-17T00:00:00+03:00"); // data sustinere test grila + o zi

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${API_URL_ENDPOINT}/licenta/stare/student`,
          {
            token: token,
          }
        );

        if (response.status === 200) {
          const studentData = response.data.student;
          setStudent(studentData);

          const { confirmareDocumente, completatChestionar } = studentData;

          if (!confirmareDocumente) {
            setPasStudent(0);
          } else if (confirmareDocumente && !completatChestionar) {
            if (currentDate >= examMaxDate) {
              setPasStudent(2);
            } else setPasStudent(1);
          } else if (confirmareDocumente && completatChestionar) {
            setPasStudent(2);
          }
        }
      } catch (error) {
        navigate("/licenta/login");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      withCredentials: true,
      url: `${API_URL_ENDPOINT}/licenta/comisie/administrare/platforma`,
      validateStatus: () => {
        return true;
      },
    }).then((res) => {
      if (res.status === 200) {
        setStareChestionarPlatforma(res.data.chestionarLicenta);
      } else {
      }
    });
  }, []);

  let navigate = useNavigate();

  const handleIncarcaFisierePage = () => {
    navigate("/licenta/upload/file");
  };

  const handleChestionarPage = () => {
    if (checkedConsimtamant) {
      navigate("/licenta/chestionar");
    } else {
      setSnackbarState({
        open: true,
        message: "Trebuie sa vă dați consimțământul!",
        severity: "warning",
      });
    }
  };

  const handleChangeConsimtamant = (event) => {
    setCheckedConsimtamant(event.target.checked);

    axios({
      method: "POST",
      data: {
        consimtamant: event.target.checked,
        token: token,
      },
      withCredentials: false,
      url: `${API_URL_ENDPOINT}/licenta/consimtamant/student`,
      validateStatus: () => {
        return true;
      },
    }).then((res) => {
      if (res.status === 200) {
        setSnackbarState({
          open: true,
          message: "Consimțământ salvat!",
          severity: "success",
        });
      }
    });
  };

  const handleDescarcaFisierNecesar = (tipFisier) => {
    axios({
      method: "POST",
      data: {
        tipFisier: tipFisier,
        token: token,
      },
      withCredentials: false,
      responseType: "blob",
      url: `${API_URL_ENDPOINT}/descarca/fisiere/necesare`,
      validateStatus: () => {
        return true;
      },
    }).then((res) => {
      if (res.status === 200) {
        const blob = new Blob([res.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${tipFisier}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        setSnackbarState({
          open: true,
          message: `${tipFisier} descărcat cu succes!`,
          severity: "success",
        });
      } else {
        setSnackbarState({
          open: true,
          message: "Eroare descarcare!",
          severity: "error",
        });
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main">
        <Header />
        {student ? (
          <Paper
            elevation={0}
            sx={{
              mt: { xs: 3, sm: 5, md: 10, lg: 6 },
              mb: "auto",
              mx: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              borderRadius: "25px",
            }}
          >
            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  mt: 5,
                  mb: 2,
                  fontFamily: "monospace",
                  fontWeight: "bold",
                  fontSize: 30,
                }}
              >
                Bine ai venit, {student.nume} {student.prenume}!
              </Typography>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  mt: 2,
                  mb: 2,
                  fontFamily: "monospace",
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Codul tău de student este: {student.idStudent}
              </Typography>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  mt: 2,
                  mb: 2,
                  fontFamily: "monospace",
                  fontWeight: "bold",
                  fontSize: 30,
                }}
              >
                Pașii candidatului
              </Typography>
              <Paper elevation={5} sx={{ mt: 2, mb: 2 }}>
                <Box sx={{ maxWidth: 400 }}>
                  <Stepper
                    activeStep={pasStudent}
                    alternativeLabel
                    sx={{ mt: 2 }}
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Paper elevation={3} sx={{ m: 2, p: 2 }}>
                    {(() => {
                      if (pasStudent === 0) {
                        return (
                          <>
                            <Typography>{textPasiStudent.pas1}</Typography>
                            <Typography fontWeight="bold">
                              Perioada încărcare acte: 02.09 - 12.09.2024
                              (23:59)
                            </Typography>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center">
                                      <InfoDialog />
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center">
                                      <Button
                                        variant="outlined"
                                        onClick={() =>
                                          handleDescarcaFisierNecesar(
                                            "FisaInscriereDPPDNivI"
                                          )
                                        }
                                        startIcon={<DownloadIcon />}
                                      >
                                        Fișă înscriere
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center">
                                      <Button
                                        variant="outlined"
                                        onClick={() =>
                                          handleDescarcaFisierNecesar(
                                            "DeclaratieAutenticitateActeNivI"
                                          )
                                        }
                                        startIcon={<DownloadIcon />}
                                      >
                                        Declarație autenticitate acte
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center">
                                      <a
                                        href="https://dppd.ase.ro/anunturi"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Mai multe informații
                                      </a>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <Button
                              variant="contained"
                              sx={{ mt: 2 }}
                              startIcon={<UploadFileIcon />}
                              onClick={handleIncarcaFisierePage}
                            >
                              Încarcă fișiere
                            </Button>
                          </>
                        );
                      } else if (pasStudent === 1) {
                        return (
                          <>
                            <Typography>{textPasiStudent.pas2}</Typography>
                            <Typography fontWeight="bold">
                              Susținere test grilă: {dataSustinere}, orele 00:00
                              - 23:59
                            </Typography>
                            <TableContainer
                              component={Paper}
                              sx={{ mt: 2, mb: 2 }}
                              hidden={!stareChestionarPlatforma}
                            >
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center">
                                      <Checkbox
                                        checked={checkedConsimtamant}
                                        onChange={handleChangeConsimtamant}
                                      />
                                    </TableCell>
                                    <TableCell align="center">
                                      Declar că voi completa testul grilă în
                                      nume propriu
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                            <Button
                              variant="contained"
                              disabled={!stareChestionarPlatforma}
                              onClick={() => handleChestionarPage()}
                            >
                              Completare test grilă
                            </Button>
                          </>
                        );
                      } else if (pasStudent === 2) {
                        return (
                          <>
                            <Typography fontWeight="bold">
                              Afișarea rezultatelor: {dataAfisareRezultate}
                            </Typography>
                            <TableContainer component={Grid}>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center">
                                      <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                      >
                                        Rezultat test grilă
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      {(() => {
                                        if (
                                          student.completatChestionar === true
                                        ) {
                                          return (
                                            <>
                                              <Typography fontWeight="bold">
                                                {student.punctaj}
                                              </Typography>
                                            </>
                                          );
                                        } else {
                                          return (
                                            <>
                                              <Typography fontWeight="bold">
                                                NECOMPLETAT
                                              </Typography>
                                            </>
                                          );
                                        }
                                      })()}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell align="center">
                                      <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                      >
                                        Rezultat admitere
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      {(() => {
                                        if (student.calificativ === 1) {
                                          return (
                                            <>
                                              <HourglassBottomOutlinedIcon color="warning" />
                                              <Typography fontWeight="bold">
                                                În așteptare
                                              </Typography>
                                            </>
                                          );
                                        }
                                        if (currentDate >= referenceDate) {
                                          if (student.calificativ === 2) {
                                            return (
                                              <>
                                                <CheckCircleIcon color="success" />
                                                <Typography fontWeight="bold">
                                                  ADMIS
                                                </Typography>
                                              </>
                                            );
                                          } else if (
                                            student.calificativ === 3
                                          ) {
                                            return (
                                              <>
                                                <ErrorOutlinedIcon color="error" />
                                                <Typography fontWeight="bold">
                                                  RESPINS
                                                </Typography>
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <HourglassBottomOutlinedIcon color="warning" />
                                                <Typography fontWeight="bold">
                                                  În așteptare
                                                </Typography>
                                              </>
                                            );
                                          }
                                        } else {
                                          return (
                                            <>
                                              <HourglassBottomOutlinedIcon color="warning" />
                                              <Typography fontWeight="bold">
                                                În așteptare
                                              </Typography>
                                            </>
                                          );
                                        }
                                      })()}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        );
                      }
                    })()}
                  </Paper>
                </Box>
              </Paper>
              <Paper>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<LogoutIcon />}
                  onClick={() => {
                    navigate("/licenta/login");
                  }}
                >
                  Delogare
                </Button>
              </Paper>
            </Box>
          </Paper>
        ) : null}
        <CustomSnackbar {...snackbarState} onClose={handleCloseSnackbar} />
      </Container>
    </ThemeProvider>
  );
}

export default StudentInfoLicenta;
